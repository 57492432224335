<template>
  <a-card v-if="isLoading" :body-style="{ height: '49.2vh', display: 'flex' }">
    <a-spin class="m-auto" size="large" />
  </a-card>
  <a-card
    v-else
    hoverable
    :title="options.title"
    :body-style="{ padding: '1px 0' }"
    class="chart-card"
  >
    <template #extra>
      <!-- need to work here -->
      <a-space v-if="options.title == chartsNames.MissedSteps">
        <a-button @click="exportToExcel">Export</a-button>
      </a-space>
      <a-space
        v-if="
          chartsNames.CycleTimeDistribution === options.title && options.data
        "
      >
        <a-typography small> Bin width: </a-typography>
        <a-button
          v-for="option in binsOption"
          :key="option"
          size="small"
          :type="selectedBinSize === option ? 'primary' : 'default'"
          @click="setBinSize(option)"
        >
          {{ option }}
        </a-button>
      </a-space>

      <a-button
        v-if="chartsNames.TimeDistribution === options.title"
        @click="isFilteredOutliers = !isFilteredOutliers"
      >
        {{ isFilteredOutliers ? 'Reset Filter' : 'Filter Outliers' }}
      </a-button>

      <a-tooltip :title="options.description">
        <QuestionOutlined class="info-icon" />
      </a-tooltip>
    </template>

    <StepAverageTime
      v-if="chartsNames.StepAverageTime === options.title"
      :options="options"
    />

    <CycleTimeDistribution
      v-if="chartsNames.CycleTimeDistribution === options.title"
      ref="cycleTimeDistribution"
      :selected-bin-size="selectedBinSize"
      :options="options"
      :chart-index="chartIndex"
    />

    <MissedSteps
      v-if="chartsNames.MissedSteps === options.title"
      :options="options"
      :chart-index="chartIndex"
    />

    <NonRelatedActivity
      v-if="chartsNames.NonRelatedActivity === options.title"
      :options="options"
    />

    <TimeDistribution
      v-if="chartsNames.TimeDistribution === options.title"
      :options="options"
      :is-filtered-outliers="isFilteredOutliers"
    />

    <DateWiseSessions
      v-if="chartsNames.DateWiseSessions === options.title"
      :options="options"
    />

    <TimelineChart
      v-if="chartsNames.DeviceWiseCycleTimeDistribution === options.title"
      :options="options"
    />
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex';
import StepAverageTime from './StepAverageTime.vue';
import CycleTimeDistribution from './CycleTimeDistribution.vue';
import MissedSteps from './MissedSteps.vue';
import NonRelatedActivity from './NonRelatedActivity.vue';
import TimeDistribution from './TimeDistribution.vue';
import TimelineChart from './TimelineChart.vue';
import DateWiseSessions from './DateWiseSessions.vue';
import { chartsNames } from 'src/config/charts-config.js';
import { QuestionOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import {
  chartsIndicesForSecondDateRange,
  chartsIndicesForFirstDateRangeWithComparison,
} from 'src/config/charts-config.js';

import ExcelJS from 'exceljs';
import { options } from 'video.js';

export default {
  components: {
    StepAverageTime,
    CycleTimeDistribution,
    MissedSteps,
    NonRelatedActivity,
    TimeDistribution,
    TimelineChart,
    DateWiseSessions,
    QuestionOutlined,
  },

  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    chartIndex: {
      type: Number,
      default: -1,
      required: true,
    },
    totalCycles: {
      type: Number,
      default: 0,
      required: true,
    },
  },

  emits: ['showCycles'],

  setup: () => {
    const cycleTimeDistribution = ref(null);
    const selectedBinSize = ref(20);
    const binsOption = [5, 10, 20, 30, 40];
    const isFilteredOutliers = ref(false);

    const setBinSize = (binSize) => {
      selectedBinSize.value = binSize;
      cycleTimeDistribution.value.setChartOptions(binSize);
    };

    return {
      chartsNames,
      selectedBinSize,
      binsOption,
      isFilteredOutliers,
      cycleTimeDistribution,
      setBinSize,
    };
  },

  computed: {
    ...mapGetters([
      'isFetchingCycles',
      'showCompareAnalytics',
      'isFetchingAnalyticsForComparison',
      'taskName',
    ]),
    isLoading() {
      const {
        showCompareAnalytics,
        isFetchingCycles,
        chartIndex,
        isFetchingAnalyticsForComparison,
      } = this;
      if (showCompareAnalytics) {
        if (chartsIndicesForFirstDateRangeWithComparison.includes(chartIndex))
          return isFetchingCycles;
        else if (chartsIndicesForSecondDateRange.includes(chartIndex))
          return isFetchingAnalyticsForComparison;
      } else {
        return isFetchingCycles;
      }
      return false;
    },
  },

  methods: {
    async exportToExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1', {
        properties: { tabColor: { argb: 'FF00FF00' } },
      });
      let accuracyPercent = this.options.data.map((data) =>
        this.determinePercentage(data)
      );

      let headers = [
        ...['', ...accuracyPercent].map((data, i) => ({
          name: `Step ${i + 1}`,
          width: 30,
        })),
      ];

      for(let i=1; i<=accuracyPercent.length; i++){
        worksheet.getColumn(i).width = 20
      }


      worksheet.addTable({
        name: 'MyTable',
        ref: 'A2',
        columns: headers,
        rows: [
          ['Board Type', ...this.options.labels],
          ['Board 1', ...accuracyPercent],
        ],
      });

      worksheet.getCell(`A1`).value = this.taskName; // Assign title to cell A1 -- THIS IS WHAT YOU'RE LOOKING FOR.
      // worksheet.mergeCells('A1:P1');
      worksheet.mergeCells(0,1,0,accuracyPercent.length+1); // Extend cell over all column headers

      worksheet.getCell('A1').style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '87CEEB' },
          bgColor: { argb: '87CEEB' },
        },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
      };
      worksheet.getCell(`A1`).alignment = { horizontal: 'center' };

      worksheet.getRow(2).eachCell({ includeEmpty: true }, function (cell) {
        
          worksheet.getCell(cell._value.model.address).style = {
            // fill: {
            //   type: 'pattern',
            //   pattern: 'solid',
            //   fgColor: { argb: color },
            //   bgColor: { argb: color },
            // },
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
        }
      });

      worksheet.getRow(3).eachCell({ includeEmpty: true }, function (cell) {
          worksheet.getCell(cell._value.model.address).style = {
            // fill: {
            //   type: 'pattern',
            //   pattern: 'solid',
            //   fgColor: { argb: color },
            //   bgColor: { argb: color },
            // },
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
        }
      });

      worksheet.getRow(4).eachCell({ includeEmpty: true }, function (cell) {
        if (cell._value.model.value !== 'Board 1') {
          let color;
          const numberValue = parseFloat(cell._value.model.value);
          if (numberValue > 90) {
            color = 'ADFF2F'; // Return green color
          }
          // Check if the parsed number is greater than 80%
          else if (numberValue > 80) {
            color = 'FFFF00'; // Return yellow color
          } else {
            color = 'FF0000'; // Return red color
          }
          worksheet.getCell(cell._value.model.address).style = {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: color },
              bgColor: { argb: color },
            },
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
          };
        }else{
          worksheet.getCell(cell._value.model.address).style = {
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
          };
        }

      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        this.saveAsExcel(buffer, 'Stats.xlsx');
      });
    },

    determineColor(value) {
      if (value == 'Board 1') return '#FFFFFF';
      const numberValue = parseFloat(value);
      if (numberValue > 90) {
        return '#ADFF2F'; // Return green color
      }
      // Check if the parsed number is greater than 80%
      else if (numberValue > 80) {
        return '#FFFF00'; // Return yellow color
      } else {
        return '#FF0000'; // Return red color
      }
    },

    saveAsExcel(buffer, fileName) {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    },

    determinePercentage(value) {
      let accuracy = this.totalCycles - value;
      return Math.floor(((accuracy / this.totalCycles) * 100).toFixed(2)) + '%';
    },

    showChart(chartName) {
      return this.chartsNames[chartName] === this.options.title;
    },
  },
};
</script>
<style>
.chart-card > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  text-align: v-bind('options.alignTitle');
}

.info-icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  font-size: 1em !important;
  margin-left: 2em;
}
</style>
